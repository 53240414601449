import * as React from 'react'

const SectionTitle = ({ title, textColor, lineColor }) => {
  return (
    <div className="flex items-center mb-12 mx-auto z-10 relative">
      <div className="shrink-0 pr-4">
        <h2
          className={`text-4xl font-alegreya md:text-5xl lg:text-6xl xl:text-7xl font-medium text-${textColor}`}
        >
          {title}
        </h2>
      </div>
      <div className="mt-4 w-screen">
        <div className={`h-px bg-${lineColor} mt-1 w-full translate-x-2`}></div>
        <div className={`h-px bg-${lineColor} mt-1 w-full`}></div>
      </div>
    </div>
  )
}

export default SectionTitle
